import React from 'react'

const date_widget = (props) => {

    const formatDate = (d) =>{
        let date = new Date(d)
        return Intl.DateTimeFormat("sr-SR").format(date)
      }
    return (
        <span className="font-bold text-xs  block p-2 text-gray-500 mt-3">Objavljeno: {formatDate(props.date)}</span>

    )
}

export default date_widget