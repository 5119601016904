import React from 'react'
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import DateWidget from "../components/date-widget"
import HeadElements from "../components/head_attributes"



const IndexPageTemplate = ({data}) => {

  

    // console.log(data)
    const {swapi:{indexpage:{title,seoTitle,searchDescription,image,url,intro,children}}} = data
    // const headAtributes = {"seoTitle":seoTitle, "seoDescription":searchDescription}

    // console.log(swapi)
    // console.log(JSON.stringify(children))

    const rewriteUrl = (url) =>{
      const baseUrl = "https://photos.izarada.com/file/internet-zarada/internet-zarada/izarada-media/"
  
      return baseUrl + url
    }
    const headAtributes = {"seoTitle":seoTitle, "seoDescription":searchDescription, "url":url,"image":rewriteUrl(image.file)}



    return (
        <Layout>
          <HeadElements headAtributes={headAtributes} />

          <div className="sm:container mx-auto text-center">
            <h1 className="text-primary font-bold  text-4xl p-8">{title}</h1>
            <p className="text-gray-500  mx-auto max-w-lg px-8 pt-2  text-center pb-6 text-sm">{intro}</p>
            <div className="w-24 h-1 bg-primary inline-block"></div>
          </div>


        


            <div className="sm:container mx-auto text-center">
            <div className="grid md:grid-cols-2 md:gap-1 mx-auto max-w-2xl lg:max-w-4xl">
            {
            
                children.map(item =>{
                return (
                
                
                <div key={item.id} className="px-14 md:px-6 py-10 md:p-14">
                    
                    <div className="relative bg-black h-40 lg:h-56">
                      <img src={rewriteUrl( item.image.file)} alt="" className="object-cover opacity-80 w-full h-full"/>
                    </div>
                    <DateWidget date={item.date}/>
                    <Link className="text-black font-bold no-underline text-xl"to={item.url}>{item.title}</Link>

                    <p className=" text-gray-500 text-sm p-3 ">{item.subTitle}</p>

                    <Link className=" text-primary no-underline font-bold text-1xl" to={item.url}>Procitaj jos...</Link>

                </div>
                
                )
                })
            
            }
            </div>
            </div>
           
        
        </Layout>
    )
}


export const query = graphql`
query getIndexPage($slug: String) {
  swapi {
    indexpage(slug: $slug) {
      title
      seoTitle
      searchDescription
      url
      image {
        file
      }
      intro
      children(limit: 10, order: "-first_published_at") {
        ... on SWAPI_BlogPage {
          id
          intro
          subTitle
          seoTitle
          seoDescription
          title
          intro
          url
          image {
            file
          }
          date
        }
      }
    }
  }
}
`
export default IndexPageTemplate